import { Checkbox, Grid, Layout, message, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import BreadCrumbGenerator from "../../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import EvenetReservationsTravelersHeader from "../../../components/Events/EventDetails/Reservations/EventReservationsTravelersHeader";
import EventsSearchbar from "../../../components/Events/EventsSearchbar";
import Eventreservation_table from "../../../components/Events/EventDetails/Reservations/EventReservationsTravelersTable";
import {
  ContactDetailRoute,
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
  EventsListingRoute,
  ReservationDetailRoute,
} from "../../../url-routes/urlRoutes";
import EventReservationsTravelersTable from "../../../components/Events/EventDetails/Reservations/EventReservationsTravelersTable";
import {
  getEventTravelersListApi,
  getSingleEventApi,
} from "../../../apis/eventsApi";
import {
  DATE_FORMAT,
  SOMETHING_WENT_WRONG,
  TIME_FORMAT,
} from "../../../utils/constants";
import moment from "moment";
import {
  addCommasInPricing,
  decodeContent,
  urlParser,
} from "../../../utils/contentParser";
import { drupalAxiosRequest } from "../../../utils/drupalAxiosRequest";
import { customRequestHeader } from "../../../utils/cutomRequestHeader";
const { Header, Content } = Layout;

function EventReservationsTravelerListingPage({ type }) {
  const [searchParamas, setSearchParams] = useSearchParams();
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint();
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const eventId = searchParamas.get("id");
  const addonId = searchParamas.get("addonId");
  const addonType = searchParamas.get("addonType");
  const [eventData, setEventData] = useState({});
  const [loadingEventData, setLoadingEventData] = useState(false);
  const [travelerList, setTravelerList] = useState([]);
  const [loadingTravelerList, setLoadingTravelerList] = useState(false);
  const [listCount, setListCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emailData, setEmailData] = useState({
    primary: false,
    type: "",
  });
  const navigate = useNavigate();
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;

  const [urlParamsObject, setUrlParamsObject] = useState({
    ...urlParser(window.location.search),
    id: searchParamas.get("id"),
    page: searchParamas.get("page") || 1,
    count: searchParamas.get("count") || 10,
    order: searchParamas.get("order") || "ASC",
  });

  const breadCrumbs =
    type === "event"
      ? [
          { title: "Events", path: EventsListingRoute },
          { title: "Detail", path: `${EventDetailsRoute}?id=${eventId}` },
          { title: "All Travelers", path: `` },
        ]
      : [
          { title: "Events", path: EventsListingRoute },
          { title: "Detail", path: `${EventDetailsRoute}?id=${eventId}` },
          {
            title: "Add-Ons",
            path: `${EventDetailsRoute}?id=${eventId}#Add-Ons`,
          },
          {
            title: "Add-Ons Detail",
            path: `${EventDetailsAddonsDetailsRoute}?id=${eventId}&addonId=${addonId}&addonType=${addonType}`,
          },
          {
            title: "All Travelers",
            path: "",
          },
        ];

  useEffect(() => {
    getEventDetails(eventId);
  }, []);

  useEffect(() => {
    setSearchParams(urlParamsObject);
    getEventTravelerList({ eventUuid: eventId, urlParamsObject });
  }, [urlParamsObject]);

  const getEventDetails = async (eventId) => {
    setLoadingEventData(true);
    const res = await getSingleEventApi(eventId);
    if (res.status) {
      setEventData(res?.data?.data);
    } else {
      setEventData({});
      message.error(SOMETHING_WENT_WRONG);
    }
    setLoadingEventData(false);
  };

  const getEventTravelerList = async (data) => {
    setLoadingTravelerList(true);
    let res = await getEventTravelersListApi({...data,type,addonId});
    res = type === "event" ? res : res.data
    if (res.status) {
      setListCount(res?.data?.pager?.total_items || res?.data?.pager?.total_count);
      setTravelerList(
        res?.data?.rows?.map((listItem) => ({
          res_id: (
            <Link
              to={`${ReservationDetailRoute}?id=${listItem?.reservation_uuid}`}
            >
              {listItem?.reservation_id || ""}
            </Link>
          ),
          name: (
            <Link to={`${ContactDetailRoute}?id=${listItem?.traveler_uuid}`}>
              {decodeContent(listItem?.traveler_name || "")}
            </Link>
          ),
          status: listItem?.reservation_status || "",
          total: listItem?.total
            ? `$${addCommasInPricing(listItem?.total?.toString())}`
            : "$0",
          balance: listItem?.balance
            ? `$${addCommasInPricing(listItem?.balance?.toString())}`
            : "$0",
          created_on: listItem?.reservation_created
            ? moment.unix(listItem?.reservation_created).format(DATE_FORMAT)
            : "",
        }))
      );
    } else {
      setTravelerList([]);
      message.error(SOMETHING_WENT_WRONG);
    }
    setLoadingTravelerList(false);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const sendEmail = async () => {
    if (!emailData.type) {
      message.error("Please select email type");
      return;
    }
    const payload = {
      event_uuid: eventData.id,
      email_type: emailData.type,
      primary_traveler: emailData.primary,
    };
    try {
      const response = await drupalAxiosRequest(
        "post",
        "/api/rest/event/email/send",
        customRequestHeader({ token: Token, defaultFormat: true }),
        payload
      );
      if (response.data.status === "success") {
        navigate('/email_monitor');
        message.success(response.data.message);
      } else {
        message.error(response.data.response.data.data.message);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleCancel = () => {
    setEmailData({});
    setIsModalOpen(false);
  };
  const emailHandler = (name, value) => {
    setEmailData((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <Header className="contact_edit main-header">
            <EventsSearchbar />
          </Header>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <div className="events-header">
            <EvenetReservationsTravelersHeader
              eventData={eventData}
              loadingEventData={loadingEventData}
              showModal={showModal}
              type={type}
            />
          </div>
          <Content>
            <EventReservationsTravelersTable
              travelerList={travelerList}
              loadingTravelerList={loadingTravelerList}
              urlParamsObject={urlParamsObject}
              setUrlParamsObject={setUrlParamsObject}
              listCount={listCount}
            />
            <Modal
              title="Confirmation"
              open={isModalOpen}
              onOk={sendEmail}
              onCancel={handleCancel}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "14px",
                }}
              >
                <div>Email Type</div>
                <Select
                  showSearch
                  placeholder="Select To Send"
                  optionFilterProp="children"
                  onChange={(event) => {
                    emailHandler("type", event);
                  }}
                  options={[
                    {
                      value: "booking_confirmation",
                      label: "Booking Confirmation",
                    },
                    {
                      value: "terms_and_conditions",
                      label: "Terms & Conditions",
                    },
                  ]}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <Checkbox
                    onChange={(event) => {
                      emailHandler("primary", event.target.checked);
                    }}
                  ></Checkbox>
                  <span>Primary Guests Only</span>
                </div>
              </div>
            </Modal>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
export default EventReservationsTravelerListingPage;
