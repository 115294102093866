import { Skeleton, Tabs, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import DataLoader from "../../../others/Loaders/DataLoader";
import AllEvents from "./EventsListingTab/AllEvents";
import "./EventsListingTabs.less";
import { getAllEvents } from "../../../redux/features/eventFeatures/eventsSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toSnakeCase, urlParser } from "../../../utils/contentParser";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";

const EventsListingTabs = ({ urlParamsObject, setUrlParamsObject }) => {
  const Token = JSON.parse(localStorage.getItem("authorization"))?.access_token;
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const TAB_NAMES = [
    "All Events",
    "Cruise",
    "Charter Cruise",
    "Tour",
    "Cruise/Tour",
  ];
  const [currentTab, setCurrentTab] = useState(1);
  const [notFound, setNotFound] = useState(false);
  const [allEventsData, setAllEventsData] = useState([]);
  const { allEvents } = useSelector((state) => state.events);
  const { allEventsResponse, loadingAllEvents } = allEvents;
  const allEventsRef = useRef(false);

  // my code starts from here

  useEffect(() => {
    let tab = urlParamsObject?.tab || "1";
    if (tab == "1") {
      setCurrentTab(tab);
    } else {
      let num = TAB_NAMES.indexOf(tab);
      if (num === -1) {
        setNotFound(true);
      } else {
        setNotFound(false);
        setCurrentTab(`${num + 1}`);
      }
    }
  }, [urlParamsObject?.tab]);

  useEffect(() => {
    if (!searchParams.toString()) {
      setSearchParams({
        count: 10,
        order: "ASC",
        page: 1,
        tab: "All Events",
      });
    }
  }, []);

  useEffect(() => {
    for (let prop in urlParamsObject) {
      if (!urlParamsObject[prop]) {
        delete urlParamsObject[prop];
      }
    }
    setSearchParams(urlParamsObject);
  }, [urlParamsObject]);

  useEffect(() => {
    if (currentTab && urlParamsObject?.tab) {
      for (let prop in urlParamsObject) {
        if (!urlParamsObject[prop]) {
          delete urlParamsObject[prop];
        }
      }

      const { order, count, event_status = "production",...rest } = urlParamsObject;
      const urlContructor = {
        ...rest,
        // type: tabArray[currentTab ? currentTab - 1 : 0],
        items_per_page: Number(urlParamsObject?.count),
        page: Number(urlParamsObject?.page) - 1,
        sort_order: urlParamsObject?.order,
        // tab: urlParamsObject?.tab !== "All Events" ? urlParamsObject?.tab : "",
        event_status: event_status,
        // sort_by: "name",
      };
      dispatch(getAllEvents(urlContructor));
    }
  }, [urlParamsObject]);

  function getTotalItems(index, item) {
    return urlParamsObject?.order === "recently_read"
      ? allEventsData?.["recently_read"]?.pager?.total_items ?? 0
      : item === "Cruise/Tour"
      ? allEventsData?.["cruise_tour"]?.pager?.total_items ?? 0
      : allEventsData?.[toSnakeCase(item)]?.pager?.total_items ?? 0;
  }

  function getTabData(index, item) {
    return urlParamsObject?.order === "recently_read"
      ? allEventsData?.["recently_read"]?.rows || []
      : item === "Cruise/Tour"
      ? allEventsData?.["cruise_tour"]?.rows
      : allEventsData?.[toSnakeCase(item)]?.rows || [];
  }

  const tabItems = TAB_NAMES.map((item, index) => {
    return {
      key: `${index + 1}`,
      label: (
        <>
          <span>{item}</span>{" "}
          {urlParamsObject?.order !== "recently_read" && (
            <span>
              {/* {loadingAllEvents ? (
                <span style={{ width: "5px" }}>
                  <Skeleton.Button
                    shape="round"
                    size="small"
                    width="5px"
                    style={{ width: "5px" }}
                  />
                </span>
              ) : (
                ` (${getTotalItems(index, item) ?? 0})`
              )} */}
              ({getTotalItems(index, item) ?? 0})
            </span>
          )}
        </>
      ),
      children: (
        <div className="activity_details pd-16 bg-white">
          <AllEvents
            allEventsData={getTabData(index, item)}
            isLoading={loadingAllEvents}
            urlParamsObject={urlParamsObject}
            setUrlParamsObject={setUrlParamsObject}
            totalItems={getTotalItems(index, item)}
          />
        </div>
      ),
    };
  });

  useEffect(() => {
    if (allEventsRef?.current) {
      if (allEventsResponse?.status) {
        setAllEventsData(allEventsResponse?.data?.[0] || {});
      } else {
        setAllEventsData([]);
        message.error(SOMETHING_WENT_WRONG);
      }
    }
    allEventsRef.current = true;
  }, [allEventsResponse]);

  const handleTabChange = (key) => {
    // setCurrentTab(key);
    setUrlParamsObject({
      ...urlParamsObject,
      count: 10,
      order: "ASC",
      page: 1,
      tab: TAB_NAMES[Number(key) - 1],
    });
  };

  if (!currentTab) {
    return <DataLoader />;
  }

  return (
    <Tabs
      activeKey={currentTab}
      className="details-tabs"
      onChange={(key) => {
        handleTabChange(key);
      }}
      items={tabItems}
    />
  );
};

export default EventsListingTabs;
